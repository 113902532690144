import { template as template_3e0f1e38a7d9464396d6891b686a9833 } from "@ember/template-compiler";
const FKControlMenuContainer = template_3e0f1e38a7d9464396d6891b686a9833(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
